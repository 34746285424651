import { flowRight, isEmpty } from 'lodash';
import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import PostTitle from '../post-title';
import { isPostSeen } from '../../services/post-utils';
import Link from '../link/internal-link';
import withFontClassName from '../../hoc/with-font-class-name';
import contentStateToString from '../../services/content-state-to-string';
import ContentExcerpt from '../content-excerpt';
import withTranslate from '../../../common/components/with-translate/with-translate';
import withAuth from '../../hoc/with-auth';
import { connect } from '../../../common/components/runtime-context';
import { getCategory } from '../../../common/selectors/categories-selectors';
import { REDUCERS } from '../../../common/components/runtime-context/reducers';
import PostCoverMedia from '../post-cover-media';
import { getIsListHashtagsEnabled } from '../../selectors/app-settings-selectors';
import Hashtags from '../hashtags';
import PostListCardHeader from './post-list-card-header';
import PostListCardFooter from './post-list-card-footer';
import styles from './post-list-card.scss';

class PostListCard extends Component {
  render() {
    const {
      post,
      category,
      origin,
      titleFontClassName,
      withContentExcerpt,
      isListHashtagsEnabled,
      contentFontClassName,
      searchQuery,
    } = this.props;

    const content = contentStateToString(post.content);
    const isEmptyContent = content.trim() === '';

    const postLink = `/${category.slug}/${post.slug}`;

    return (
      <div
        className={classNames(styles.container, contentFontClassName, {
          [styles.isPinned]: post.isPinned,
        })}
        data-hook="post-list-item"
      >
        <PostListCardHeader post={post} origin={origin} />
        <PostTitle
          className={classNames(titleFontClassName, styles.postTitle, 'forum-text-color', {
            [styles.isSeen]: isPostSeen(post),
          })}
          title={post.title}
          to={postLink}
          clamp={2}
          post={post}
          category={category}
          query={searchQuery}
        />
        {withContentExcerpt && !isEmptyContent && (
          <Link to={postLink} className="forum-text-color" tabIndex="-1">
            <ContentExcerpt
              className={styles.contentExcerpt}
              content={content.slice(0, 300)}
              isContentConverted
              query={searchQuery}
              clamp={3}
            />
          </Link>
        )}
        {withContentExcerpt && (
          <PostCoverMedia post={post} postLink={postLink}>
            {/* // TODO width */}
            {media =>
              media && (
                <Link to={postLink}>
                  <div className={styles.coverMedia}>{media}</div>
                </Link>
              )
            }
          </PostCoverMedia>
        )}
        {isListHashtagsEnabled && !isEmpty(post.hashtags) && (
          <Hashtags hashtags={post.hashtags} dataHook="post-hashtags" />
        )}
        <PostListCardFooter post={post} />
      </div>
    );
  }
}

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  isListHashtagsEnabled: getIsListHashtagsEnabled(state, host.style),
  category: getCategory(state, ownProps.post.categoryId),
});

PostListCard.propTypes = {
  post: PropTypes.object.isRequired,
  origin: PropTypes.string.isRequired,
  withContentExcerpt: PropTypes.bool,
  contentFontClassName: PropTypes.string,
  searchQuery: PropTypes.string,
};

export default flowRight(
  withFontClassName,
  withTranslate,
  withAuth,
  connect(mapRuntimeToProps, [REDUCERS.CATEGORIES]),
)(PostListCard);
